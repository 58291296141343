<div *ngIf="!showForm">
    Loading...
</div>
<form-actions [formData]="formData"></form-actions>
<div class="row" *ngIf="existingRiskId">
    <div class="col-12 text-center">
        <a [routerLink]="['/risks', existingRiskId]" target="_blank">Original Risk</a>
    </div>
</div>
<risk-assessment-form-0
    #stage0Form
    [formData]="formData"
    *ngIf="showForm && targetStage === 0 && (!formData || !formData.isDeleted)">
</risk-assessment-form-0>
<risk-assessment-form-1
    #stage1Form
    [formData]="formData"
    *ngIf="showForm && targetStage === 1 && (!formData || !formData.isDeleted)">
</risk-assessment-form-1>
<risk-assessment-form-2
    #stage2Form
    [formData]="formData"
    *ngIf="showForm && targetStage === 2 && (!formData || !formData.isDeleted)">
</risk-assessment-form-2>
<risk-assessment-form-3
    #stage3Form
    [formData]="formData"
    *ngIf="showForm && targetStage === 3 && (!formData || !formData.isDeleted)">
</risk-assessment-form-3>

<!---
    The read only view of the form should be always visible throughout the process
--->
<div class="{{ targetStage === 4 ? '' : 'pt-2' }}">&nbsp;</div>
<risk-assessment-form-4 [formData]="formData" *ngIf="showForm && targetStage > 0 && !!formData"></risk-assessment-form-4>

<div class="row" *ngIf="childForms">
    <div class="col-12">
        <h1 class="text-uppercase text-white bg-teal p-2 px-3 my-2">Child Forms</h1>
        <div class="pl-2">
            <child-forms-tree [childForms]="childForms"></child-forms-tree>
        </div>
    </div>
</div>

