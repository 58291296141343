var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericService } from "./GenericService";
var RiskService = /** @class */ (function (_super) {
    __extends(RiskService, _super);
    function RiskService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    RiskService.prototype.createRisk = function (params) {
        return this.http.post(RiskService.apiPrefix, params);
    };
    RiskService.prototype.importRisks = function (id) {
        return this.http.get(RiskService.importPrefix + "/" + id);
    };
    RiskService.prototype.getRisks = function (offset, pageSize, sortBy, order, searchFilter, locationFilter, riskLevelFilter, riskStatusFilter, riskTypeId, groupFilter) {
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 20; }
        if (sortBy === void 0) { sortBy = 'id'; }
        if (order === void 0) { order = 'asc'; }
        if (searchFilter === void 0) { searchFilter = ''; }
        if (locationFilter === void 0) { locationFilter = ''; }
        if (riskLevelFilter === void 0) { riskLevelFilter = ''; }
        if (riskStatusFilter === void 0) { riskStatusFilter = ''; }
        if (riskTypeId === void 0) { riskTypeId = ''; }
        if (groupFilter === void 0) { groupFilter = ''; }
        var queryParams = new HttpParams({
            fromObject: {
                skip: offset.toString(),
                limit: pageSize.toString(),
                sortBy: sortBy,
                order: order,
                searchFilter: searchFilter,
                locationFilter: locationFilter,
                riskLevelFilter: riskLevelFilter,
                riskStatusFilter: riskStatusFilter,
                riskTypeId: riskTypeId,
                groupFilter: groupFilter
            }
        });
        return this.http.get(RiskService.apiPrefix, { params: queryParams });
    };
    RiskService.prototype.getRiskFromId = function (id) {
        return this.http.get(RiskService.apiPrefix + "/" + id.toString());
    };
    RiskService.prototype.updateRisk = function (risk) {
        return this.http.put(RiskService.apiPrefix, risk);
    };
    RiskService.prototype.deleteRisk = function (id) {
        return this.http.delete(RiskService.apiPrefix + "/" + id.toString());
    };
    RiskService.prototype.generateAssessment = function (id) {
        return this.http.get(RiskService.apiPrefix + "/" + id.toString() + "/newAssessment");
    };
    RiskService.apiPrefix = '/api/risk';
    RiskService.importPrefix = "/api/risk-import";
    return RiskService;
}(GenericService));
export { RiskService };
