import { Component } from "@angular/core";
import { Risk } from "../../../../common/Risk";
import { RiskLikelihoodModalState } from "../../../service/ModalService";

@Component({
  selector: 'risk-likelihood-modal',
  templateUrl: './riskLikelihoodModal.component.html',
  styles: [`
	.rotate {
		/* FF3.5+ */
		-moz-transform: rotate(-90.0deg);
		/* Opera 10.5 */
		-o-transform: rotate(-90.0deg);
		/* Saf3.1+, Chrome */
		-webkit-transform: rotate(-90.0deg);
		/* IE6,IE7 */
		filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);
		/* IE8 */
		-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
		/* Standard */
		transform: rotate(-90.0deg);
	}
  `]
})
export class RiskLikelihoodModalComponent {
  public value: string = '';

  public likelihoodOptions = Risk.LikelihoodOptions.reverse();

  constructor(private state: RiskLikelihoodModalState) {
  }

  ok() {
    this.state.modal.close();
  }
}
