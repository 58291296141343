<div class="row">
  <div class="col-12 text-lg-left">
    <h1 class="text-uppercase text-orange">Risk Dashboard</h1>
  </div>
</div>
<div class="row">
  <div class="col-12 col-lg-8" *ngIf="risk">
    <div class="row my-3">
      <div class="col-12">
        <span>Risk Summary:</span>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <textarea class="form-control" placeholder="Summary" autosize replaceAmpersand [minRows]="3" [spellcheck]="true"
          [value]="" [(ngModel)]="risk.summary" [readonly]=false></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-6 my-2">
        <span class="mb-3">Current Risk Level:</span>
        <input class="form-control" [readonly]="true" [(ngModel)]="risk.riskLevel" />
      </div>
      <div class="col-6 my-2">
        <span class="mb-3">Current Risk Status:</span>
        <input class="form-control" [readonly]="true" [(ngModel)]="risk.riskStatus" />
      </div>
      <div class="col-6 my-2">
        <span class="mb-3">Date of Initial Assessment:</span>
        <input class="form-control" [value]="risk.createdAt | date: 'mediumDate'" [readonly]=true />
      </div>
      <div class="col-6 my-2">
        <span class="mb-3">Date of Last Assessment:</span>
        <input class="form-control"
          [value]="risk.dateOfLastAssessment ? (risk.dateOfLastAssessment | date: 'mediumDate') : 'N/A'" 
          [readonly]=true />
      </div>
      <div class="col-6 my-2">
        <span class="mb-3">Date of Next Assessment:</span>
        <input class="form-control"
          [value]="risk.dateOfNextAssessment ? (risk.dateOfNextAssessment | date: 'mediumDate') : 'N/A'"
          [readonly]=true />
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1">
    <button class="btn btn-block btn-lg btn-success rounded-0" (click)="updateRisk()">
      <span class="capitalize">update</span>
    </button>
  </div>
  <div class="col-12 col-md-6 col-lg-4 col-xl-2 col-xxl-1" *ngIf="isAdministrator && !isProduction">
    <button class="btn btn-block btn-lg btn-danger rounded-0" (click)="deleteRisk()">
      <span class="capitalize">delete</span>
    </button>
  </div>
</div>

<div *ngIf="!!risk" class="row mt-4">
  <div class="col-9 text-lg-left">
    <h1 class="text-uppercase text-orange">Assessments</h1>
  </div>
  <div class="col-3 text-md-right">
    <button class="btn btn-md btn-orange" (click)="createNewAssessment()">Review
      Risk</button>
  </div>
</div>
<ram-dashboard *ngIf="!!risk && navOptions.existingAssessments" [headless]=true></ram-dashboard>