import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest } from 'rxjs';
import { CategoryService } from 'service/CategoryService';
import { RiskService } from 'service/RiskService';
import { logger } from 'service/util/Logger';
import { Session } from 'service/util/Session';
import { stringifyObjectValues } from 'service/util/stringifyObjectValues';
import { CurrentUserService } from 'service/currentUser/CurrentUserService';
import { environment } from 'environments/environment';
var EditRiskComponent = /** @class */ (function () {
    function EditRiskComponent(router, activatedRoute, riskService, session, currentUserService, categoryService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.riskService = riskService;
        this.session = session;
        this.currentUserService = currentUserService;
        this.categoryService = categoryService;
        this.className = "EditRiskComponent";
        this.risk = null;
        this.navOptions = {
            existingAssessments: false
        };
        this.isAdministrator = false;
        this.isProduction = false;
    }
    EditRiskComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isAdministrator = this.currentUserService.isAdministrator.getValue();
        this.isProduction = !!environment.production;
        combineLatest([
            this.activatedRoute.params,
            this.activatedRoute.queryParams
        ]).subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            /** if page param is undefined push default query params  */
            if (!queryParams.page || !queryParams.category) {
                _this.pushDefaultQueryParams();
                return;
            }
            _this.navOptions.existingAssessments = true;
            if (params.riskId) {
                if (/^\d+$/.test(params.riskId) !== true) {
                    console.error('Invalid risk id router param');
                    _this.router.navigate(['/404'], { replaceUrl: true });
                    return;
                }
                _this.riskId = parseInt(params.riskId, 10);
            }
            _this.riskService.getRiskFromId(_this.riskId).subscribe(function (risk) {
                _this.risk = risk;
            });
        });
    };
    /**
     * @description Writes the params which are used for the dashboard component to the url
     */
    EditRiskComponent.prototype.pushDefaultQueryParams = function () {
        var _this = this;
        var signature = this.className + ".pushDefaultQueryParams: ";
        logger.silly(signature + "Updating Params for Dashboard");
        // Setting DRM-Task as default category
        combineLatest([
            this.categoryService.getCategories(),
            this.activatedRoute.params
        ])
            .subscribe(function (_a) {
            var categories = _a[0], params = _a[1];
            var drmTaskCategory = categories.find(function (cat) { return cat.name === 'Risk Task'; });
            var queryParams = stringifyObjectValues({
                page: 1,
                showAll: 'all',
                sortBy: 'createdAt',
                order: 'desc',
                group: '',
                category: drmTaskCategory && drmTaskCategory.id,
                riskId: params.riskId
            });
            _this.router.navigate(['.'], {
                relativeTo: _this.activatedRoute,
                replaceUrl: true,
                queryParams: queryParams,
                queryParamsHandling: '',
            });
        });
    };
    /**
     * @description Handles the "New Assessment" button
     */
    EditRiskComponent.prototype.createNewAssessment = function () {
        var _this = this;
        this.riskService.generateAssessment(this.riskId).subscribe(function (data) {
            _this.session.requestPrevPage.next({ defaultUrl: '/ram-dashboard', filter: 'ram-dashboard' });
        }, function (err) {
            console.error(err);
        });
    };
    EditRiskComponent.prototype.deleteRisk = function () {
        var _this = this;
        if (!this.risk)
            return;
        this.riskService.deleteRisk(this.riskId).subscribe(function (data) {
            _this.router.navigate(["/risks"], { replaceUrl: true });
        }, function (err) {
            console.error(err);
        });
    };
    EditRiskComponent.prototype.updateRisk = function () {
        var _this = this;
        if (!this.risk)
            return;
        var newSummary = this.risk.summary;
        if (newSummary.length > 0) {
            this.riskService.updateRisk({ id: this.riskId, summary: newSummary }).subscribe(function (data) {
                _this.router.navigate(["/risks"], { replaceUrl: true });
            }, function (err) {
                console.error(err);
            });
        }
    };
    return EditRiskComponent;
}());
export { EditRiskComponent };
