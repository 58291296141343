<header [formData]="formData" *ngIf="!hideHeader"></header>
<page-title [text]="isExistingRisk ? 'Risk Assessment' : 'Initial Assessment'" titleType="2"></page-title>

<form>
	<div class="row">
		<div class="col-12 col-lg-8 border-lg-right border-dashed">

			<div class="row" *ngIf="form.reassign.isVisible">
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label>Reassign Report to another Staff Member:</label>
						<button-toggle [value]="form.reassign.value" (valueChange)="form.reassign.value = $event"
							[disabled]="readOnly"></button-toggle>
					</div>
				</div>
				<div class="col-12 col-lg-6" *ngIf="form.reassign.value===true">
					<div class="form-group">
						<label>&nbsp;</label>
						<user-select [field]="form.reassignToUserId" [disabled]="readOnly"></user-select>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="form.riskLevelChanged.isVisible">
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label>Has there been a change in the risk level?</label>
						<button-toggle [value]="form.riskLevelChanged.value" (valueChange)="updateRiskLevelChanged($event)"
							[disabled]="readOnly"></button-toggle>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="form.riskRemoved.isVisible">
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label>This Risk has been removed:</label>
						<button-toggle [value]="form.riskRemoved.value" (valueChange)="updateRemoveState($event)"
							[disabled]="readOnly"></button-toggle>
					</div>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.occuranceLikelyhood.isVisible || form.severity.isVisible || form.riskLevel.isVisible">
				<div class="col-12 col-sm-6" *ngIf="form.occuranceLikelyhood.isVisible">
					<div class="form-group">
						<label>Likelihood of occurance <small><a (click)="showLikelihoodMatrix()">(<u>Matrix</u>)</a></small>:</label>
						<select2
							class="form-control select2-form-control {{!readOnly && form.occuranceLikelyhood.iifIsValid('border-success','border-danger')}}"
							[data]="likelihoodSelectOptions" [options]="{ placeholder: 'Select an option' }"
							[value]="form.occuranceLikelyhood.value"
							(valueChanged)="form.occuranceLikelyhood.value = $event.value"
							[disabled]="readOnly || isExistingRisk">
						</select2>
					</div>
				</div>

				<div class="col-12 col-sm-6" *ngIf="form.severity.isVisible">
					<div class="form-group">
						<label>Severity <small><a (click)="showSeverityMatrix()">(<u>Matrix</u>)</a></small>:</label>
						<select2
							class="form-control select2-form-control {{!readOnly && form.severity.iifIsValid('border-success','border-danger')}}"
							[data]="severitySelectOptions" [options]="{ placeholder: 'Select an option' }"
							[value]="form.severity.value" (valueChanged)="form.severity.value = $event.value"
							[disabled]="readOnly || isExistingRisk">
						</select2>
					</div>
				</div>

				<div class="col-12 col-sm-6" *ngIf="form.riskLevel.isVisible">
					<div class="form-group">
						<label>Risk level <small><a (click)="showRiskMatrix()">(<u>Risk Matrix</u>)</a></small>:</label>
						<select2
							class="form-control select2-form-control {{!readOnly && form.riskLevel.iifIsValid('border-success','border-danger')}}"
							[data]="riskSelectOptions" [options]="{ placeholder: 'Select Likelihood and Severity' }"
							[value]="form.riskLevel.value" (valueChanged)="form.riskLevel.value = $event.value"
							[disabled]="true">
						</select2>
					</div>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.mitigationMeasures.isVisible">
				<div class="col-12">
					<span>Mitigation Measures:</span>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.mitigationMeasures.isVisible">
				<div class="col-12">
					<textarea
						class="form-control {{!readOnly && form.mitigationMeasures.iifIsValid('border-success','border-danger')}}"
						placeholder="Mitigation Measures" autosize replaceAmpersand [minRows]="3" [spellcheck]="true"
						[(ngModel)]="form.mitigationMeasures.value" [ngModelOptions]="{standalone: true}"
						[disabled]="readOnly"></textarea>
					<small class="form-text text-danger" *ngIf="form.mitigationMeasures.showErrorHelp()">Invalid
						Mitigiation Measure</small>
				</div>
			</div>

			<div *ngIf="form.howResidentHasBeenInformed.isVisible">
				<div class="row my-3">
					<div class="col-12">
						<span>Outline how resident has been informed about risk: </span>
					</div>
				</div>
				<div class="row my-3">
					<div class="col-12">
						<textarea
							class="form-control {{!readOnly && form.howResidentHasBeenInformed.iifIsValid('border-success','border-danger')}}"
							placeholder="Comments" autosize replaceAmpersand [minRows]="3" [spellcheck]="true"
							[(ngModel)]="form.howResidentHasBeenInformed.value" [ngModelOptions]="{standalone: true}"
							[disabled]="readOnly"></textarea>
						<small class="form-text text-danger"
							*ngIf="form.howResidentHasBeenInformed.showErrorHelp()">Field must be filled</small>
					</div>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.recommendations.isVisible">
				<div class="col-12">
					<span>Recommendations on how to proceed:</span>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.recommendations.isVisible">
				<div class="col-12">
					<textarea
						class="form-control {{!readOnly && form.recommendations.iifIsValid('border-success','border-danger')}}"
						placeholder="Recommendations" autosize replaceAmpersand [minRows]="3" [spellcheck]="true"
						[(ngModel)]="form.recommendations.value" [ngModelOptions]="{standalone: true}"
						[disabled]="readOnly"></textarea>
					<small class="form-text text-danger" *ngIf="form.recommendations.showErrorHelp()">Invalid
						Mitigiation Measure</small>
				</div>
			</div>
			
			<div class="row my-3" *ngIf="form.postMitigationSeverity.isVisible || form.postMitigationOccuranceLikelyhood.isVisible || form.postMitigationRiskLevel.isVisible">
				<div class="col-12 col-sm-6" *ngIf="form.postMitigationOccuranceLikelyhood.isVisible">
					<div class="form-group">
						<label>Post Mitigation Likelihood of occurance <small><a (click)="showLikelihoodMatrix()">(<u>Matrix</u>)</a></small>:</label>
						<select2
							class="form-control select2-form-control {{!readOnly && form.postMitigationOccuranceLikelyhood.iifIsValid('border-success','border-danger')}}"
							[data]="likelihoodSelectOptions" [options]="{ placeholder: 'Select an option' }"
							[value]="form.postMitigationOccuranceLikelyhood.value"
							(valueChanged)="form.postMitigationOccuranceLikelyhood.value = $event.value"
							[disabled]="readOnly">
						</select2>
					</div>
				</div>

				<div class="col-12 col-sm-6" *ngIf="form.postMitigationSeverity.isVisible">
					<div class="form-group">
						<label>Post Mitigation Severity <small><a (click)="showSeverityMatrix()">(<u>Matrix</u>)</a></small>:</label>
						<select2
							class="form-control select2-form-control {{!readOnly && form.postMitigationSeverity.iifIsValid('border-success','border-danger')}}"
							[data]="severitySelectOptions" [options]="{ placeholder: 'Select an option' }"
							[value]="form.postMitigationSeverity.value"
							(valueChanged)="form.postMitigationSeverity.value = $event.value" [disabled]="readOnly">
						</select2>
					</div>
				</div>

				<div class="col-12 col-sm-6" *ngIf="form.postMitigationRiskLevel.isVisible">
					<div class="form-group">
						<label>Post Mitigation Risk level <small><a (click)="showRiskMatrix()">(<u>Risk Matrix</u>)</a></small>:</label>
						<select2
							class="form-control select2-form-control {{!readOnly && form.postMitigationRiskLevel.iifIsValid('border-success','border-danger')}}"
							[data]="riskSelectOptions" [options]="{ placeholder: 'Select Likelihood and Severity' }"
							[value]="form.postMitigationRiskLevel.value"
							(valueChanged)="form.postMitigationRiskLevel.value = $event.value" [disabled]="true">
						</select2>
					</div>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.summary.isVisible">
				<div class="col-12">
					<span>Comments:</span>
				</div>
			</div>

			<div class="row my-3" *ngIf="form.summary.isVisible">
				<div class="col-12">
					<textarea class="form-control {{form.summary.iifIsValid('border-success','border-danger')}}"
						autosize replaceAmpersand [minRows]="3"
						[placeholder]="readOnly ? '' : 'Enter your brief summary here...'" [disabled]="readOnly"
						[spellcheck]="true" [(ngModel)]="form.summary.value"
						[ngModelOptions]="{standalone: true}"></textarea>
					<small class="form-text text-danger" *ngIf="form.summary.showErrorHelp()">Invalid Summary</small>
				</div>
			</div>

			<div class="row my-3">
				<div class="col-12">
					<follow-up-widget #followUpWidget [model]="form.followUps.value"
						(modelChange)="form.followUps.value=$event" [readOnly]="readOnly"></follow-up-widget>
				</div>
			</div>
		</div>
		<div class="col-12 offset-0 col-lg-4 pt-0 pt-lg-4">
			<div class="row">
				<div class="col-12 offset-0 col-xl-10 offset-xl-1">
					<document-uploads [(documents)]="documents" [readOnly]="readOnly" [disableTicking]="true"></document-uploads>
				</div>
			</div>
		</div>
	</div>

	<footer [formRecord]="formRecord" [readOnly]="readOnly" (onSubmit)="submit($event)"></footer>
</form>