/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./riskLikelihoodModal.component";
import * as i3 from "../../../service/ModalService";
var styles_RiskLikelihoodModalComponent = [".rotate[_ngcontent-%COMP%] {\n\t\t\n\t\t-moz-transform: rotate(-90.0deg);\n\t\t\n\t\t-o-transform: rotate(-90.0deg);\n\t\t\n\t\t-webkit-transform: rotate(-90.0deg);\n\t\t\n\t\tfilter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);\n\t\t\n\t\t-ms-filter: \"progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)\";\n\t\t\n\t\ttransform: rotate(-90.0deg);\n\t}"];
var RenderType_RiskLikelihoodModalComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RiskLikelihoodModalComponent, data: {} });
export { RenderType_RiskLikelihoodModalComponent as RenderType_RiskLikelihoodModalComponent };
function View_RiskLikelihoodModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "th", [["scope", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.description; _ck(_v, 4, 0, currVal_1); }); }
export function View_RiskLikelihoodModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Risk Likelihood Matrix"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RiskLikelihoodModalComponent_1)), i0.ɵdid(10, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.likelihoodOptions; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_RiskLikelihoodModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "risk-likelihood-modal", [], null, null, null, View_RiskLikelihoodModalComponent_0, RenderType_RiskLikelihoodModalComponent)), i0.ɵdid(1, 49152, null, 0, i2.RiskLikelihoodModalComponent, [i3.RiskLikelihoodModalState], null, null)], null, null); }
var RiskLikelihoodModalComponentNgFactory = i0.ɵccf("risk-likelihood-modal", i2.RiskLikelihoodModalComponent, View_RiskLikelihoodModalComponent_Host_0, {}, {}, []);
export { RiskLikelihoodModalComponentNgFactory as RiskLikelihoodModalComponentNgFactory };
