<div class="modal-header">
	<h4 class="modal-title">Risk Severity Matrix</h4>
	<button type="button" class="close" aria-label="Close" (click)="ok()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<table class="table table-striped">
		<thead>
			<tr>
				<th [colSpan]="4" class="text-center">Consequences</th>
			</tr>
			<tr>
				<th class="text-center">&nbsp;</th>
				<th class="text-center">Clinical</th>
				<th class="text-center">Operational</th>
				<th class="text-center">Reputational</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let severity of severityOptions; let i = index;">
				<th scope="row">{{severity.text}}</th>
				<td>{{severity.clinicalDesc}}</td>
				<td>{{severity.operationalDesc}}</td>
				<td>{{severity.reputationalDesc}}</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (click)="ok()">OK</button>
</div>