import { Component } from "@angular/core";
import { Risk } from "../../../../common/Risk";
import { RiskSeverityModalState } from "../../../service/ModalService";

@Component({
  selector: 'risk-severity-modal',
  templateUrl: './riskSeverityModal.component.html',
  styles: [`
	.rotate {
		/* FF3.5+ */
		-moz-transform: rotate(-90.0deg);
		/* Opera 10.5 */
		-o-transform: rotate(-90.0deg);
		/* Saf3.1+, Chrome */
		-webkit-transform: rotate(-90.0deg);
		/* IE6,IE7 */
		filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.083);
		/* IE8 */
		-ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0.083)";
		/* Standard */
		transform: rotate(-90.0deg);
	}
  `]
})
export class RiskSeverityModalComponent {
  public severityOptions = Risk.SeverityOptions.reverse();

  constructor(private state: RiskSeverityModalState) {
  }

  ok() {
    this.state.modal.close();
  }
}
