import { Component, OnInit, ViewChild } from '@angular/core';

import { FormService } from "../../../../service/FormService";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Session } from "../../../../service/util/Session";
import has = Reflect.has;
import { ChildFormTreeNode, IFormOutputModel } from "../../../../../common/contracts/form";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ComponentCanDeactivate } from "../../../shared/canDeactivate/ComponentCanDeactivate";
import { RiskAssessmentFormStage0Component } from "./stage0/riskAssessmentFormStage0.component";
import { RiskAssessmentFormStage1Component } from "./stage1/riskAssessmentFormStage1.component";
import { RiskAssessmentFormStage2Component } from "./stage2/riskAssessmentFormStage2.component";
import { RiskAssessmentFormStage3Component } from "./stage3/riskAssessmentFormStage3.component";
import { ErrorHandlerService } from "../../../../service/ErrorHandlerService";
import { FormComponent } from '../../../../model/FormComponent';


@Component({
	selector: 'app-admin',
	styleUrls: ['./riskAssessmentForm.component.scss'],
	templateUrl: './riskAssessmentForm.component.html',
})
export class RiskAssessmentFormComponent extends ComponentCanDeactivate implements OnInit {

	public targetStage: number;
	public showForm: boolean = false;

	public formData: IFormOutputModel | null = null;
	public childForms: ChildFormTreeNode[] | null;
	public existingRiskId: number | null = null;

	@ViewChild('stage0Form') stage0Form: RiskAssessmentFormStage0Component;
	@ViewChild('stage1Form') stage1Form: RiskAssessmentFormStage1Component;
	@ViewChild('stage2Form') stage2Form: RiskAssessmentFormStage2Component;
	@ViewChild('stage3Form') stage3Form: RiskAssessmentFormStage3Component;

	constructor(
		public formService: FormService,
		public activatedRoute: ActivatedRoute,
		public session: Session,
		private router: Router,
		private errorHandler: ErrorHandlerService,
	) {
		super();
	}

	canDeactivate(): boolean {
		if (!this.showForm) return true;

		if (this.formData && this.formData.isDeleted) {
			return true;
		}

		if (this.targetStage === 0)
			return this.stage0Form.canDeactivate();

		if (this.targetStage === 1)
			return this.stage1Form.canDeactivate();

		if (this.targetStage === 2)
			return this.stage2Form.canDeactivate();

		if (this.targetStage === 3)
			return this.stage3Form.canDeactivate();

		return true;
	}

	ngOnInit() {
		this.session.lockInputRx(
			this.activatedRoute.params.pipe(switchMap((params: Params) => {
				if (!has(params, "taskId")) {
					this.showForm = true;
					this.targetStage = 0;
					return of(null);
				} else {
					return this.formService.getFormById(params["taskId"]);
				}
			}))
		).subscribe((existingForm: IFormOutputModel | null) => {

			if (!existingForm) {
				return;
			}
			/**
			 *  fix for 'Parent Form' link, if form's category is 'Follow-Up' redirect to /followUps/:reportId
			 **/
			if (existingForm.category.name === 'Follow-Up') {
				this.router.navigate(['/followUp', existingForm.id], { replaceUrl: true });
				return;
			}

			/**
			 *  fix for 'Parent Form' link, if form's category is 'Report' redirect to /report/:reportId
			 **/
			if (existingForm.category.name === 'Audit') {
				this.router.navigate(['/audit', existingForm.id], { replaceUrl: true });
				return;
			}

			this.formData = existingForm;

			if (this.formData.records.length === 0) {
				this.errorHandler.raiseError("Record did not contain valid data. Please contact a system administrator for assistance.", "Invalid Form Data");
			}

			this.targetStage = this.formData["stage"] || 0;
			this.showForm = true;

			if (this.formData.childForms && this.formData.childForms.length) {
				this.childForms = this.formData.childForms;
			} 

			this.linkExistingRisk();

		}, (err) => {
			console.error("Cannot get form by id", err);
			this.errorHandler.handleHttpError(err);
		});
	}

	linkExistingRisk() {
		const lastStage0Submission = FormComponent.getMostRecentSubmission(this.formData, false, 0);

		if (lastStage0Submission) {
			this.existingRiskId = FormComponent.getIntData(lastStage0Submission, 'existingRisk');
		}
	}
}
