<div class="modal-header">
    <h4 class="modal-title">Risk Likelihood Matrix</h4>
    <button type="button" class="close" aria-label="Close" (click)="ok()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table class="table table-striped">
		<tbody>
			<tr *ngFor="let likelihood of likelihoodOptions; let i = index;">
				<th scope="row">{{likelihood.text}}</th>
				<td>{{likelihood.description}}</td>
			</tr>
		</tbody>
	  </table>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="ok()">OK</button>
</div>
